import { Link } from 'gatsby';
import React from 'react';

import Spacer from '@components/Spacer/Spacer';
import HomeLayout from '@layouts/HomeLayout';

const NotFoundPage = () => (
  <HomeLayout>
    <Spacer />

    <h2>Sorry, this page could not be found.</h2>

    <Spacer height={16} />

    <p>
      Please use the navigation links to choose a new page or{' '}
      <Link to="/">go back home</Link>.
    </p>
  </HomeLayout>
);

export default NotFoundPage;
